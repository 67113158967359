// == PILL == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// pill
.pill {
  @include borderRadius(16, 16, 16, 16);
  height: 2rem;
  background-repeat: no-repeat;
  background-position: rem-calc(6) center;
  color: $color-white;
  font-weight: 600;
  font-size: rem-calc(14);
  display: inline-block;
  padding: 0 1rem;

  &--topic {
    background: $color-grey-medium;
    span {
      line-height: 2rem;
      color: $color-grey-darkest;
    }

    &.pill--active {
      background: $color-grey-darkest;
      span {
        color: $color-white;
      }
    }
  }

  &--mood {
    padding-left: 0;
    span {
      line-height: 2rem;
      padding-left: 2rem;
    }
  }

  &--highlight {
    cursor: pointer;
  }

  &--small {
    height: rem-calc(18);
    border-radius: rem-calc(9);
    padding: 0 rem-calc(8);
    font-size: rem-calc(12);
    background: $color-grey-darkest;
    
    span {
      line-height: rem-calc(18);
      color: $color-white;
    }
  }

  &--icon {
    width: 2rem;
  }

  &--sad {
    background-color: $color-sad;
    background-image: url('../../assets/sad.svg');

    &.pill--highlight {
      background-color: $color-grey-medium;
      background-image: url('../../assets/sad--dark.svg');

      &.pill--active {
        background-color: $color-sad;
        background-image: url('../../assets/sad.svg');
      }
    }
  }

  &--meh {
    background-color: $color-meh;
    background-image: url('../../assets/meh.svg');

    &.pill--highlight {
      background-color: $color-grey-medium;
      background-image: url('../../assets/meh--dark.svg');

      &.pill--active {
        background-color: $color-meh;
        background-image: url('../../assets/meh.svg');
      }
    }
  }

  &--happy {
    background-color: $color-happy;
    background-image: url('../../assets/happy.svg');

    &.pill--highlight {
      background-color: $color-grey-medium;
      background-image: url('../../assets/happy--dark.svg');

      &.pill--active {
        background-color: $color-happy;
        background-image: url('../../assets/happy.svg');
      }
    }
  }
}

// pill list
.pill-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;

  &__item {
    margin: 0 rem-calc(4);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
