// == UTILITIES == //
// - Classes - //
// flex break
.flex-break {
  flex-basis: 100%;
  height: 0;
}

// - Functions - //
// rem-calc
@function rem-calc($value) {
  @return ($value / 16) + rem;
}

// - Mixins - //
// box shadow
@mixin boxShadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

// border radius
@mixin borderRadius($topleft, $topright, $bottomright, $bottomleft) {
  border-radius: rem-calc($topleft) rem-calc($topright) rem-calc($bottomright) rem-calc($bottomleft);
}
