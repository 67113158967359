// == COLORS == //
// main
$color-red : #FF6C6C;

// greys
$color-white : #FFFFFF; // card bg
$color-grey-lightest : #FAFAFA; // main bg
$color-grey-light : #F2F3F3; // container bg
$color-grey-medium : #E1E3E4; // lines and pill
$color-grey-dark : #BBC5D0; // date
$color-grey-darkest : #757C85; // body bg
$color-black : #232323;

// moods
$color-sad : $color-red;
$color-meh: #FFCD6C;
$color-happy: #A3E7A1;
