// == GENERAL == //
// imports
@import '../../node_modules/normalize.css/normalize.css';
@import './base/colors';
@import './base/util';
@import './base/typography';
@import './base/layout';

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background: $color-grey-darkest;
}

