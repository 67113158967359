// == MOOD == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// mood
.mood {
  position: relative;
  margin-bottom: 2rem;

  &__percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
  }

  .sentiment-percentage {
    transform: rotate(-90deg);

    circle {
      fill: none;
      stroke-width: 1rem;
    }

    .full {
      stroke: $color-grey-medium;
    }

    .percentage {
      stroke-dasharray: 270;
      stroke-dashoffset: 270;
      stroke: $color-grey-darkest;
    }
  }

  small {
    text-align: center;
    display: block;
    font-weight: 600;
    position: absolute;
    width: 100%;
    top: calc(100% + 0.5rem);
  }

  &--sad {
    .mood__percentage {
      color: $color-sad;
    }
    .sentiment-percentage {
      .full {
        stroke: transparentize($color-sad, 0.7);
      }
      .percentage {
        stroke: $color-sad;
      }
    }
  }

  &--meh {
    .mood__percentage {
      color: $color-meh;
    }
    .sentiment-percentage {
      .full {
        stroke: transparentize($color-meh, 0.7);
      }
      .percentage {
        stroke: $color-meh;
      }
    }
  }

  &--happy {
    .mood__percentage {
      color: $color-happy;
    }
    .sentiment-percentage {
      .full {
        stroke: transparentize($color-happy, 0.7);
      }
      .percentage {
        stroke: $color-happy;
      }
    }
  }
}

// mood list
.mood-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: calc(100% + 1rem);
  left: -0.5rem;

  @media only screen and (min-width: 1480px) {
    flex-wrap: nowrap;
  }

  &__item {
    height: auto;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    padding: 0 30%;
    margin: 0 auto;

    @media only screen and (min-width: 1480px) {
      flex-shrink: 1;
      flex-basis: calc(100% / 3);
      padding: 0;
      margin: 0 0.5rem;
    }
  }
}
