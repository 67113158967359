// == CONVERSATION == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// highlight bar
.highlight-bar {
  position: absolute;
  top: 0;
  background: $color-grey-lightest;
  width: calc(100% - 27rem);
  z-index: 1;
  height: 5.5rem;
  left: 7rem;
  border-bottom: 1px solid $color-grey-medium;
  padding: 0 2rem;
  display: flex;

  @media only screen and (min-width: 1480px) {
    width: calc(100% - 33rem);
  }

  h6 {
    margin: 1rem 0 0;
  }

  &__left {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;

    .pill-list {
      margin-top: auto;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    margin-left: auto;

    .pill-list {
      margin-top: auto;
    }
  }
}


// animations
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// page loader
.page-loader {
  position: absolute;
  width: rem-calc(40);
  height: rem-calc(40);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    background: transparent;
    border-top: rem-calc(2) solid $color-red;
    border-right: rem-calc(2) solid $color-red;
    border-bottom: rem-calc(2) solid transparent;
    border-radius: 50%;
    animation: 1s spin linear infinite;
  }
}