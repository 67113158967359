// == TYPOGRAPHY == //
// Open Sans
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

// General
body {
  font-family: 'Open Sans', sans-serif;
  color: $color-grey-darkest;
  font-weight: 400;
}

h6 {
  margin: 2rem 0 1rem;
  font-weight: 400;
  text-transform: uppercase;
}
