// == LAYOUT == //
// container
.container {
  @include boxShadow;
  height: calc(100vh - 2rem);
  margin: 1rem;
  overflow: hidden;
  background: $color-grey-light;
  position: relative;
  border-radius: rem-calc(4);
  display: flex;
}

main {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: calc(100% - 27rem);
  background: $color-grey-lightest;
  height: calc(100% - 5.5rem);
  margin-top: 5.5rem;
  overflow-y: scroll;
  box-shadow: -10px 0px 32px 16px rgba(0, 0, 0, 0.05);
  position: relative;

  @media only screen and (min-width: 1480px) {
    flex-basis: calc(100% - 33rem);
  }
}

aside {
  position: relative;
  height: calc(100vh - 2rem);
  background: $color-grey-lightest;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 20rem;
  border-left: 1px solid $color-grey-medium;
  right: 0;
  border-radius: 0 rem-calc(4) rem-calc(4) 0;
  padding: 2rem;

  h4 {
    margin: 0;
  }

  @media only screen and (min-width: 1480px) {
    flex-basis: 26rem;
  }
}
