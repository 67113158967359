// == POST == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

// post
.post {
  @include boxShadow;
  position: relative;
  background: $color-white;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: rem-calc(4);

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    width: 1rem;
    top: 50%;
    left: -1rem;
    background: $color-grey-dark;
  }

  &__header {
    display: flex;
  }

  &__image {
    width: rem-calc(48);
    height: auto;
    display: block;
    border-radius: 50%;
    margin-right: 1rem;
    align-self: flex-start
  }

  &__details {
    display: flex;
    flex-wrap: wrap;

    h3 {
      margin: 0 0 1rem 0;
      display: inline-block;
      font-weight: 700;
      font-size: rem-calc(14);
    }

    & > span {
      font-size: rem-calc(14);
      color: $color-grey-dark;
      font-weight: 600;

      &::before {
        content: '';
        display: inline-block;
        background: $color-grey-dark;
        width: rem-calc(4);
        height: rem-calc(4);
        border-radius: 50%;
        margin: 0 rem-calc(8);
        transform: translateY(-50%);
      }
    }

    .pill--topic {
      align-self: flex-start;
    }
  }

  &__text {
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1.4em;
  }

  &--active {
    box-shadow: 0 0px 32px 16px rgba(0, 0, 0, 0.1);
  }

  &--inactive {
    height: 4rem;

    &::before {
      content: '...';
      font-size: 2rem;
      line-height: rem-calc(10);
    }

    .post__header,
    .post__text {
      display: none;
    }
    .pill {
      opacity: 0.7;
    }
  }

  .pill--mood {
    @include borderRadius(16, 4, 4, 16);
    @include boxShadow;
    position: absolute;
    width: rem-calc(44);
    right: rem-calc(-6);
    top: 1rem;
  }
}

// post list
.post-list {
  margin: 2rem;
  padding: 0;
  list-style: none;
  display: block;
  height: 100%;
  
  ul {
    margin: 0;
    padding: 0 0 0 2rem;
    list-style: none;
  }

  &__item {
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 1px;
      top: 0;
      left: 1rem;
      background: $color-grey-dark;
    }
    
    &--1 {
      margin-bottom: 2rem;
      & > .post {
        &::after {
          display: none;
        }
      }
    }

    &--2 {
      &:last-child {
        & > .post {
          z-index: 1;
          &::after {
            background-color: transparent;
            height: 50%;
            width: 1rem;
            top: 0;
            border-left: 1px solid $color-grey-dark;
            border-bottom: 1px solid $color-grey-dark;
          }
        }
        &::after {
          content: '';
          position: absolute;
          display: block;
          background: $color-grey-lightest;
          left: -1rem;
          width: 1px;
          height: 100%;
          top: auto;
          bottom: 0;
        }
      }
    }

    &--3 {
      &::before {
        display: none;
      }
      &:last-child {
        &::before {
          display: block;
          background: $color-grey-lightest;
          left: -1rem;
          height: 50%;
          top: auto;
          bottom: 0;
        }
      }
    }
  }
}
