// == NAVIGATION == //
// imports
@import '../../scss/base/util';
@import '../../scss/base/colors';

.navigation {
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 7rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    width: 5rem;
    height: auto;
    display: block;
  }

  .menu {

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
    }

    li {
      margin-bottom: rem-calc(24);
      position: relative;
      overflow: hidden;

      a {
        position: relative;
        z-index: 1;
        width: rem-calc(24);
        height: rem-calc(24);
        display: block;
        text-indent: -9999em;
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: rem-calc(24);
        height: rem-calc(24);
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &--main {
      margin-top: 2.5rem;

      li:nth-child(1)::after {
        background-image: url('../../assets/dashboard.svg');
      }
      li:nth-child(2)::after {
        background-image: url('../../assets/conversations.svg');
      }
      li:nth-child(3)::after {
        background-image: url('../../assets/current.svg');
      }
    }

    &--user {
      margin-top: auto;

      li:nth-child(1)::after {
        background-image: url('../../assets/inbox.svg');
      }
      li:nth-child(2)::after {
        background-image: url('../../assets/mail.svg');
      }
      li:nth-child(3)::after {
        background-image: url('../../assets/print.svg');
      }
      li:nth-child(4)::after {
        background-image: url('../../assets/settings.svg');
      }
      li:nth-child(5)::after {
        background-image: url('../../assets/info.svg');
      }
    }
  }

  .profile {
    background: $color-red;
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
    border-radius: 50%;
    text-align: center;
    line-height: 3rem;
    color: $color-white;
    text-decoration: none;
    font-weight: 700;
    font-size: rem-calc(18);
  }
}